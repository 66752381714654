import { ImageObject } from '@utils/types';
import classNames from 'classnames';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import { A11y, Navigation, Pagination } from 'swiper';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';

const Carousel = ({
	children,
	as: Component = 'section',
	className = '',
}: {
	children: React.ReactNode[];
	as?: React.ElementType;
	className?: string;
}): JSX.Element => (
	<Component
		className={classNames('section__carousel', {
			[className]: !!className,
		})}>
		<Swiper
			className="carousel"
			modules={[Pagination, Navigation, A11y]}
			spaceBetween={0}
			slidesPerView={1}
			pagination={{ clickable: true }}
			navigation={true}>
			{children.map((child, key) => (
				<SwiperSlide key={key}>{child}</SwiperSlide>
			))}
		</Swiper>
	</Component>
);

const Panel = ({
	title,
	children,
	image,
	link,
	lazy = true,
}: {
	title: string;
	children?: React.ReactNode;
	image: ImageObject;
	link?: string;
	lazy?: boolean;
}) => {
	const gatsbyImage = getImage(image.src);

	return (
		<article
			className={classNames('carousel__slide', {
				'carousel--no-link': !link,
			})}>
			{gatsbyImage && (
				<div className="carousel__slide__image image-background">
					<GatsbyImage
						image={gatsbyImage}
						alt={image.alt}
						objectFit="cover"
						loading={lazy ? 'lazy' : 'eager'}
					/>
				</div>
			)}
			<div className="row">
				<div className="col xl7 l9 m12">
					<div className="carousel__slide__caption">
						<h1 className="carousel__slide__title">{title}</h1>
						{children && (
							<p className="carousel__slide__summary">
								{children}
							</p>
						)}
						{link && (
							<Link
								to={link}
								className="carousel__slide__link link-coverer">
								Find out more
							</Link>
						)}
					</div>
				</div>
			</div>
		</article>
	);
};

Carousel.Panel = Panel;

export default Carousel;
