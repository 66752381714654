import { ImageObject } from '@utils/types';
import classNames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

const Breaker = ({
	children,
	image,
	as: Component = 'section',
	className = '',
}: {
	children: React.ReactNode;
	as?: React.ElementType;
	image: ImageObject;
	className?: string;
}) => {
	const gatsbyImage = getImage(image.src);

	return (
		<Component
			className={classNames('breaker sector--software', {
				[className]: !!className,
			})}>
			{gatsbyImage && (
				<div className="breaker__bkg">
					<div className="image-background">
						<GatsbyImage
							image={gatsbyImage}
							alt={image.alt}
							objectFit="cover"
						/>
					</div>
				</div>
			)}
			<div className="breaker__content breaker__content--right">
				{children}
			</div>
		</Component>
	);
};

export default Breaker;
