// i18next-extract-mark-ns-start index

import { LINKS } from '@utils/constants';
import { IProductFamily } from '@utils/types';
import { graphql } from 'gatsby';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import Breaker from '@components/Breaker';
import Button from '@components/Button';
import Carousel from '@components/Carousel';
import Deck from '@components/Deck';
import List from '@components/List';
import PageSection from '@components/PageSection';
import Tile from '@components/Tile';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';

const IndexPage = ({ data }) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t('seo_title')}
				description={t('seo_description')}
			/>

			<Carousel>
				<Carousel.Panel
					title={t('carousel1_title')}
					image={{
						src: data.carouselA.childImageSharp.gatsbyImageData,
						alt: '',
					}}
					link={LINKS.PRODUCTS}>
					<Trans i18nKey="carousel1_desc">Simulation tools to model propulsion technologies for transportation of the future</Trans>
				</Carousel.Panel>


				<Carousel.Panel
					title={t('carousel2_title')}
					image={{
						src: data.carouselB.childImageSharp.gatsbyImageData,
						alt: '',
					}}
					link={LINKS.PRODUCTS_FLUID_DYNAMICS}>
					<Trans i18nKey="carousel2_desc">Our leading-edge tools and solutions facilitate design and optimisation with non-carbon fuels, hydrogen fuel, e-fuels and bio-fuels</Trans>
				</Carousel.Panel>
				<Carousel.Panel
					title={t('carousel3_title')}
					image={{
						src: data.carouselC.childImageSharp.gatsbyImageData,
						alt: '',
					}}
					link={LINKS.PRODUCTS_STRUCTURAL_MECHANICS}>
					<Trans i18nKey="carousel3_desc">Find out how our mechanical suite can help reduce materials
						and encourage sustainable product development</Trans>
				</Carousel.Panel>
				<Carousel.Panel
					title={t('carousel4_title')}
					image={{
						src: data.carouselD.childImageSharp.gatsbyImageData,
						alt: '',
					}}
					link={LINKS.PRODUCTS_SYSTEMS_ENGINEERING}>
					<Trans i18nKey="carousel4_desc">Realis Simulation’s leading-edge tools are ready to support
						our customer’s journey to zero tailpipe emissions</Trans>
				</Carousel.Panel>
			</Carousel>

			<PageSection className="section__split-content" layout="none">
				<div className="col xl8 m12 m-spacer">
					<Trans i18nKey="intro">
						<p>
							Realis Simulation is a trusted partner to the world’s leading transport manufacturers, OEMs, and Tier 1 suppliers.
							We develop simulation software to deliver fast, repeatable, and predictive results across existing and emerging propulsion technologies,
							enabling the journey to net zero emissions.
							Our products model all major propulsion technologies, rapidly answering the ‘What ifs’ in a timely and cost-effective manner,
							reducing the requirement for expensive hardware testing.
							The suite of leading-edge simulation tools facilitate design and calibration programmes as we transition into the future,
							supporting clean combustion fuels, sustainable powertrain systems, efficient vehicle transmission and
							optimised hybrid internal combustion/electric solutions.
						</p>

						<h3>Accelerating Possibilities</h3>
						<p>
							Our mission is to continue enabling expert design engineers to model propulsion technologies fit for the future.
							Realis engineering analysis tools deliver rapid results for fluids, structures and systems which empower our customers
							to address the planet’s biggest transport decarbonisation challenges.
							Based on nearly 30 years of software expertise, Realis focuses on delivering highly tailored tools and application engineering
							support to provide fast, repeatable, and predictive results across existing and emerging technologies.
						</p>
					</Trans>
				</div>
				<div className="col xl4 m12 m-spacer">
					<Trans i18nKey="sidebar">
						<p>
							Realis Simulation offers these solutions to complex customer challenges by modelling:
						</p>
						<List>
							<li>Clean fuel technology</li>
							<li>Sustainable powertrains</li>
							<li>Efficient transmissions</li>
							<li>Optimised hybrid systems</li>
						</List>
					</Trans>
				</div>
			</PageSection>

			<PageSection title={t("Benefits for our customers")} theme="greytint">
				<List type="grid-column">
					<Trans i18nKey="benefits">
						<li>
							<strong>
								Easy-to-use and intuitive software tools
							</strong>
							. Seamlessly integrate into existing processes and
							eco-systems
						</li>
						<li>
							<strong>Reduced time and costs</strong>. By lowering
							dependency on hardware prototyping and testing
						</li>
						<li>
							<strong>
								Easy management of increasingly complex designs
							</strong>
							. Created by regulation changes and consumer performance
							expectations
						</li>
						<li>
							<strong>
								Trusted guidance on the right solution for the right
								application
							</strong>
							. Offering solutions for six key markets from passenger
							car to marine
						</li>
					</Trans>
				</List>
			</PageSection>

			<PageSection
				title={t("_ProductFamilies")}
				className="section--padding--adjust"
				layout="wide">
				<Deck>
					{data.productFamilies.edges.map(
						({ node }: { node: IProductFamily }, i: number) => (
							<Tile
								type="icon"
								key={i}
								title={t(node.titlekey) /* i18next-extract-disable-line */}
								image={{
									src: node.image,
									alt: t(node.titlekey) /* i18next-extract-disable-line */
								}}
								link={node.link}>
								{t(node.contentkey) /* i18next-extract-disable-line */}
							</Tile>
						)
					)}
				</Deck>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>

			<Breaker
				image={{
					src: data.breaker.childImageSharp.gatsbyImageData,
					alt: '',
				}}>
				<Trans i18nKey="breaker">
					<h1 className="breaker__title">Customer support</h1>
					<p className="breaker__blurb">
						A newly organised support portal has been designed to
						provide easy access to software downloads, training
						documents, and open support tickets. Access to the exclusive
						support portal is permitted only to registered Realis
						Simulation customers.
					</p>
					<Link to={LINKS.SUPPORT} className="breaker__link link-coverer">
						Read more
					</Link>
				</Trans>
			</Breaker>
		</Page>
	);
};

export default IndexPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["index", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		carouselA: file(relativePath: { eq: "content/hero-netzero.png" }) {
			...imageCarousel
		}
		carouselB: file(relativePath: { eq: "content/hero-e-fuel.png" }) {
			...imageCarousel
		}
		carouselC: file(
			relativePath: { eq: "content/hero-sustainability.png" }) {
			...imageCarousel
		}
		carouselD: file(
			relativePath: { eq: "content/hero-electrification.png" }) {
			...imageCarousel
		}
		productFamilies: allProductFamiliesJson {
			edges {
				node {
					titlekey
					contentkey
					image {
						...imageTile
					}
					link
				}
			}
		}
		resources: allMarkdownRemark(
			filter: { 
				fileAbsolutePath: { regex: "/resources/" } 
				fields: { language: { eq: $language } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			limit: 8
		) {
			nodes {
				fields {
					slug
				}
				frontmatter {
					title
					summary
					image {
						...imageStandard
					}
					type
				}
			}
		}
		breaker: file(relativePath: { eq: "content/breaker-support.jpg" }) {
			...imageBreaker
		}
	}
`;
